<template>
  <div class="operations">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="50"></v-progress-circular>
    </v-overlay>
    <template>
      <v-row>
        <v-col cols="2" class="mt-3">
          <v-menu
            ref="datesMenu"
            v-model="datesMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="datesStr"
                label="Период"
                prepend-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                class="textField"
              ></v-text-field>
            </template>
            <v-date-picker
              range
              no-title
              min="2019-01-01"
              type="month"
              locale="ru-Latn"
              v-if="datesMenu"
              v-model="dates"
              full-width
              @click:month="saveMonth"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="10" class="mt-3 btnLeft">
          <v-btn dense small color="primary" @click="downloadXls" class="px-1">
            <span>Скачать</span><v-icon>mdi-file-excel</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </template>
    <h1 class="subheading grey--text">Сводная таблица</h1>
    <v-text-field
      v-model="totalsTable.search"
      append-icon="mdi-magnify"
      label="Поиск"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      dense
      :headers="totalsTable.headers"
      :hide-default-footer="true"
      :items="myOperations.total"
      :items-per-page="10"
      :search="totalsTable.search"
      class="elevation-1"
    >
      <template v-slot:item.sum="{ item }">
        {{ item.sum.toLocaleString('en-US').replaceAll(',', '\u00A0') }}
      </template>
    </v-data-table>

    <h1 class="subheading grey--text mt-4">Реестр операций</h1>
    <v-text-field
      v-model="register.search"
      append-icon="mdi-magnify"
      label="Поиск"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      dense
      :headers="headersReestr"
      :items="myOperations.all"
      :items-per-page="10"
      :search="register.search"
      class="elevation-1"
      @click:row="expandOperations"
      mobile-breakpoint="0"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table dense class="mb-3">
            <tbody>
              <tr>
                <td>
                  Id
                </td>
                <td>
                  {{ item.id }}
                </td>
              </tr>
              <!-- <tr>
                <td>
                  Дата/время
                </td>
                <td>
                  {{ dateFormat(item.dateTime) }}
                </td>
              </tr> -->
              <tr>
                <td>
                  Контрагент
                </td>
                <td>
                  {{ item.counterparty }}
                </td>
              </tr>
              <tr>
                <td>
                  Автомобиль
                </td>
                <td>
                  {{ item.car }}
                </td>
              </tr>

              <tr>
                <td>
                  Тип
                </td>
                <td>
                  {{ item.type }}
                </td>
              </tr>
              <tr>
                <td>
                  Комментарий
                </td>
                <td>
                  {{ item.comment }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:item.sum="{ item }">
        {{ item.sum.toLocaleString('en-US').replaceAll(',', '\u00A0') }}
      </template>
      <template v-slot:item.dateTime="{ item }">
        {{ dateFormat(item.dateTime) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import xlsx from 'json-as-xlsx';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      datesMenu: false,
      loading: false,
      dates: [
        moment()
          // .add(-2, 'month')
          .startOf('month')
          .format('YYYY-MM-DD'),
        moment()
          .endOf('month')
          .format('YYYY-MM-DD'),
      ],
      totalsTable: {
        search: '',
        headers: [
          // {
          //     text: 'Автомобиль',
          //     align: 'start',
          //     sortable: false,
          //     value: 'title',
          // },
          { text: 'Статья', value: 'article' },
          { text: 'Сумма', value: 'sum' },
          // { text: 'Выручка', value: 'proceeds' },
          // { text: 'Расходы', value: 'consumption' },
          // { text: 'Комиссия УК', value: 'comission' },
          // { text: 'Доход авто', value: 'clearProfit' },
        ],
        headersDesktop: [
          {
            text: 'Автомобиль',
            align: 'start',
            sortable: false,
            value: 'title',
          },
          { text: 'Статья', value: 'article' },
          { text: 'Сумма', value: 'sum' },
          { text: 'Выручка', value: 'proceeds' },
          { text: 'Расходы', value: 'consumption' },
          { text: 'Комиссия УК', value: 'comission' },
          { text: 'Доход авто', value: 'clearProfit' },
        ],
      },
      register: {
        search: '',
        headers: [
          // {
          //   text: 'id',
          //   align: 'start',
          //   sortable: false,
          //   value: 'id',
          // },
          { text: 'Дата', value: 'dateTime' },
          // { text: 'Контрагент', value: 'counterparty' },
          { text: 'Статья', value: 'article' },
          // { text: 'Автомобиль', value: 'car' },
          { text: 'Сумма', value: 'sum' },
          // { text: 'Тип', value: 'type' },
          // { text: 'Комментарий', value: 'comment' },
        ],
        headersDesktop: [
          {
            text: 'id',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'Дата/время', value: 'dateTime' },
          { text: 'Контрагент', value: 'counterparty' },
          { text: 'Статья', value: 'article' },
          { text: 'Автомобиль', value: 'car' },
          { text: 'Сумма', value: 'sum' },
          { text: 'Тип', value: 'type' },
          { text: 'Комментарий', value: 'comment' },
        ],
      },
    };
  },
  methods: {
    expandOperations(item, slot) {
      if (this.isMobile) return slot.expand(!slot.isExpanded);
      return slot.expand(false);
    },
    downloadXls() {
      let data = [
        {
          sheet: 'Сводная таблица',
          columns: [
            { label: 'Статья', value: 'article' },
            { label: 'Сумма', value: 'sum' },
          ],
          content: this.myOperations.total,
        },
        {
          sheet: 'Реестр операций',
          columns: [
            {
              label: 'id',
              value: 'id',
            },
            {
              label: 'Дата/время',
              value: (row) => this.dateFormat(row.dateTime),
            },
            { label: 'Контрагент', value: 'counterparty' },
            { label: 'Статья', value: 'article' },
            { label: 'Автомобиль', value: 'car' },
            { label: 'Сумма', value: 'sum' },
            { label: 'Тип', value: 'type' },
            { label: 'Комментарий', value: 'comment' },
          ],
          content: this.myOperations.all,
        },
      ];

      let settings = {
        fileName: `Мои операции ${moment(this.dates[0]).format('DD.MM.YYYY') +
          '-' +
          moment(this.dates[1]).format('DD.MM.YYYY')}`, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
      };

      xlsx(data, settings);
    },
    dateFormat(ISOdate) {
      return moment(ISOdate).format('DD.MM.YYYY');
    },
    minDateTo(type) {
      if (type == 'dateAt') return moment('2019-01-01').format('YYYY-MM-DD');
      if (type == 'dateTo') {
        if (this.dateAt) return moment(this.dateAt).format('YYYY-MM-DD');
        else return moment().format('YYYY-MM-DD');
      }
    },
    saveMonth() {
      if (this.dates.length == 2) {
        this.datesMenu = false;
      }
    },
    async findStatistic(dates) {
      this.loading = true;
      await this.$store.dispatch('getMyOperations', dates);
      this.loading = false;
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 960;
    },
    headersReestr() {
      return this.isMobile
        ? this.register.headers
        : this.register.headersDesktop;
    },
    datesStr() {
      moment.locale('ru');
      return `${moment(this.dates[0]).format('MMMM')} - ${moment(this.dates[1])
        .endOf('month')
        .format('MMMM')}`;
    },
    ...mapGetters(['myOperations']),
  },
  watch: {
    dates: async function(dates) {
      if (dates.length != 2) return;
      this.findStatistic(dates);
    },
  },
  async mounted() {
    await this.findStatistic(this.dates);
  },
};
</script>

<style lang="scss" scoped>
.textField {
  min-width: 200px;
}
.btnLeft {
  text-align: right;
}
</style>
