var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operations"},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1),[_c('v-row',[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"2"}},[_c('v-menu',{ref:"datesMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"textField",attrs:{"label":"Период","prepend-icon":"mdi-calendar-month","readonly":"","outlined":""},model:{value:(_vm.datesStr),callback:function ($$v) {_vm.datesStr=$$v},expression:"datesStr"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datesMenu),callback:function ($$v) {_vm.datesMenu=$$v},expression:"datesMenu"}},[(_vm.datesMenu)?_c('v-date-picker',{attrs:{"range":"","no-title":"","min":"2019-01-01","type":"month","locale":"ru-Latn","full-width":""},on:{"click:month":_vm.saveMonth},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"mt-3 btnLeft",attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"px-1",attrs:{"dense":"","small":"","color":"primary"},on:{"click":_vm.downloadXls}},[_c('span',[_vm._v("Скачать")]),_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1)],1)],_c('h1',{staticClass:"subheading grey--text"},[_vm._v("Сводная таблица")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.totalsTable.search),callback:function ($$v) {_vm.$set(_vm.totalsTable, "search", $$v)},expression:"totalsTable.search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.totalsTable.headers,"hide-default-footer":true,"items":_vm.myOperations.total,"items-per-page":10,"search":_vm.totalsTable.search},scopedSlots:_vm._u([{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sum.toLocaleString('en-US').replaceAll(',', '\u00A0'))+" ")]}}])}),_c('h1',{staticClass:"subheading grey--text mt-4"},[_vm._v("Реестр операций")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.register.search),callback:function ($$v) {_vm.$set(_vm.register, "search", $$v)},expression:"register.search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headersReestr,"items":_vm.myOperations.all,"items-per-page":10,"search":_vm.register.search,"mobile-breakpoint":"0"},on:{"click:row":_vm.expandOperations},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"mb-3",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v(" Id ")]),_c('td',[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('tr',[_c('td',[_vm._v(" Контрагент ")]),_c('td',[_vm._v(" "+_vm._s(item.counterparty)+" ")])]),_c('tr',[_c('td',[_vm._v(" Автомобиль ")]),_c('td',[_vm._v(" "+_vm._s(item.car)+" ")])]),_c('tr',[_c('td',[_vm._v(" Тип ")]),_c('td',[_vm._v(" "+_vm._s(item.type)+" ")])]),_c('tr',[_c('td',[_vm._v(" Комментарий ")]),_c('td',[_vm._v(" "+_vm._s(item.comment)+" ")])])])])],1)]}},{key:"item.sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sum.toLocaleString('en-US').replaceAll(',', '\u00A0'))+" ")]}},{key:"item.dateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.dateTime))+" ")]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }